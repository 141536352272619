<template>
  <div
    class="container"
    v-title
    id="monthlyStatistics"
    :data-title="$t('i18n.monthlyStatistics')"
  >
    <div id="outer-title">{{ $t("i18n.monthlyStatistics") }}</div>
    <div class="half">
      <el-date-picker
        v-model="month"
        type="month"
        @change="
          (val) => {
            initData(val, 'pieOption');
          }
        "
        placeholder="选择月"
      ></el-date-picker>
      <jl-chart
        name="option"
        class="chartCircle"
        :columns="pieOption"
      ></jl-chart>
    </div>
    <div class="half">
      <el-date-picker
        v-model="preMonth"
        type="month"
        @change="
          (val) => {
            initData(val, 'pieOption1');
          }
        "
        placeholder="选择月"
      ></el-date-picker>
      <jl-chart
        name="option"
        class="chartCircle"
        :columns="pieOption1"
      ></jl-chart>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import jlChart from "../../components/chart";
export default {
  name: "CountMonthBills",
  components: {
    jlChart,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const state = reactive({
      month: "",
      preMonth: "",
      pieOption: {
        title: t("i18n.monthlyStatistics"),
        type: "pie",
        series: [
          {
            type: "pie",
            selectedMode: "single",
            radius: [0, "20%"],
            label: {
              position: "inner",
              fontSize: 14,
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
          {
            type: "pie",
            radius: ["35%", "50%"],
            labelLine: {
              length: 30,
            },
            data: [],
          },
        ],
      },
      pieOption1: {
        title: t("i18n.monthlyStatistics"),
        type: "pie",
        series: [
          {
            type: "pie",
            selectedMode: "single",
            radius: [0, "20%"],
            label: {
              position: "inner",
              fontSize: 14,
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
          {
            type: "pie",
            radius: ["35%", "50%"],
            labelLine: {
              length: 30,
            },
            data: [],
          },
        ],
      },
    });

    const init = () => {
      let yearData = new Date().getFullYear();
      state.month = yearData + "-" + addzero(new Date().getMonth());
      state.preMonth = yearData + "-" + addzero(new Date().getMonth() - 1);
      initData(state.month, "pieOption");
      initData(state.preMonth, "pieOption1");
    };

    const addzero = (month) => {
      if (month < 10) {
        return "0" + "" + month;
      } else return month;
    };

    const initData = (month, key) => {
      initInnerData(month, key);
      initOuterData(month, key);
    };

    const initInnerData = async (month, key) => {
      if (month instanceof Date) {
        month = proxy.$defined
          .format(month)
          .slice(0, proxy.$defined.format(month).length - 3);
      }
      let { data } = await proxy.$api.maintenance.getCountMonthInner({
        month: month,
      });
      state[key].series[0].data = data;
    };

    const initOuterData = async (month, key) => {
      if (month instanceof Date) {
        month = proxy.$defined
          .format(month)
          .slice(0, proxy.$defined.format(month).length - 3);
      }
      let { data } = await proxy.$api.maintenance.getCountMonthOuter({
        month: month,
      });
      state[key].series[1].data = data;
    };

    init();

    return {
      ...toRefs(state),
      initData,
    };
  },
};
</script>

<style lang="scss">
#monthlyStatistics {
  .half {
    width: 49%;
    vertical-align: top;
    display: inline-block;
    position: relative;
    .el-date-editor {
      position: absolute;
      z-index: 2;
      top: 15px;
    }
  }
}
@media screen and (max-width: 768px) {
  #monthlyStatistics {
    .half {
      width: 100%;
      .el-date-editor {
        top: 55px;
        left: 50px;
      }
    }
  }
}
</style>
